.accordion-container {
    border: none;
    color: $base-text;
    background-color: $white;

    .k-item {
        padding: $margin-xs 0;
        border: none;
        outline: none !important;

        .k-link {    
            background: $white;
            border: none;
            border-bottom: 1px solid $border-lighter-color;
            display: flex;
            justify-content: space-between;
            padding: $margin-xs 0;
            box-shadow: none !important;

            &:hover, &:focus {
                background: rgba($primary, 0.02);
                border-bottom: 1px solid $primary;
                box-shadow: none;
                cursor: pointer;

                .k-icon {color: $primary; }
            }

            .k-panelbar-item-text {
                padding:0 $margin-s;
                text-transform:none;
                font-size: rem(22);
                font-weight: 400;
                font-family: $headline-font;
                line-height: 1.5em;
                color: $base-text;
            }

            .k-icon {
                color: $base-text;
                font-size: rem(24);
                margin-right: $margin-s;
            }

            &.k-state-selected {
                border-color: $primary;
                background: $white;
                color: $base-text;
                border-radius: 0;
            
                .k-icon {color: $primary; }

                &:hover, &:focus {
                    background: rgba($primary, 0.02);
                    border-bottom: 1px solid $primary;
                    box-shadow: none;
    
                    .k-icon {color: $primary; }
                }
            }
        }

        &.k-state-expanded .k-link { 
            border-color: $primary;
            
            .k-icon {color: $primary; }
        }

        .k-animation-container {
            padding: $margin-m $margin-s 0;
        }
    }

    .k-form-field {
        margin-bottom: $margin-m !important;

        label { margin-bottom: $margin-xs; }
    }
}