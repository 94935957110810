main {
    @include clearfix;
    padding-top: $header-height;

    .container {
        align-items: stretch;
        height: calc(100vh - (#{$header-height} + #{$margin-xs}));  //header height
        padding-right:2px
    }

    hr {
        margin: $margin-m 0;
        border-color: $border-lighter-color;
        border-width: 1px 0 0;
        border-style: solid;
        display: block;
        flex: 0 0 auto;  
    }
}

.main-content {
    padding: $margin-l $margin-l $margin-l calc(#{$logo-section-width} + #{$margin-l});
    height: 100%;
    overflow:auto;

    .section {
        background: $white;
        border-radius: $border-radius;
        padding: 0 0 $margin-m;
    }
}
.one-row-flex-form-section {
    display: flex;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    margin: 0 -#{$margin-s} !important;

    .k-form-field {
        width: 100%;
        min-width: rem(200);
        max-width: rem(400);
        margin: 0 $margin-s $margin-m !important;
    }
}
//Dashboard page
main .k-tilelayout {
    background: none;
    padding: 0 !important;
    gap: $margin-m !important;

    .k-card {
        background: $white;
        border-radius: $border-radius;
        border: 1px solid rgba($border-color,0.6);
        padding: $margin-m $margin-s;

        .k-cursor-nwse-resize {
            bottom: $margin-s !important;
            right: $margin-s !important;
            width: auto !important;
            height: auto !important;
            opacity: 0;
            visibility: hidden;
            transform: translateX(-55%);
            @include prefix(transition, all 0.3s ease);

            &::before {
                content: '\f424';
                font-family: $icon-font;
                font-size: rem(16);
                color: rgba($black, 0.2);
                font-weight: 700;
                transform: rotate(90deg);
            }
        }
        &:hover {
            .k-cursor-nwse-resize {
                opacity: 1;
                visibility: visible;
                transform: none;
            }
        }

        &.no-header .k-card-header { display: none;}
        &.align-center .k-card-body { @include center;}

        &.status-card {
            background: #fffbef;
            border: 1px solid rgba($warning,0.2);

            .k-card-body p {
                font-size: rem(20);
                color: $warning;
            }
        }
        &.rating-card .card-container{
            text-align: center;
        }
        
        .k-card-header { border: none;}

        .k-card-title {
            font-family: $headline-font;
            color: $base-text;
            font-size: rem(34);

            &.small { font-size: rem(24); }
        }

        &.two-column-card {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;

            .k-card-header,
            .k-card-body {
                flex-grow: 1;
                text-align: center;
                flex-basis: 50%;
            }

            .k-card-title { font-size: rem(28); }
        }

        .card-container {
            p { 
                color: $base-border;
                font-size: calc($base-font-size - 4px);
            }

            .icon-wrap { @include custom-icon;}
        }

        &.progressbar-card {
            &.green {
                .k-progressbar { 
                    background-color: rgba($success,0.1); 
                    .k-progress-status-wrap .k-progress-status {
                        color: $series-e;
                    }
                }
                .k-state-selected { background: $success; }
                .icon-wrap { @include custom-icon($success);}
            }
            &.yellow {
                .k-progressbar { 
                    background-color: rgba($warning,0.1); 
                    .k-progress-status-wrap .k-progress-status {
                        color: $warning;
                    }
                }
                .k-state-selected { background: $warning; }
                .icon-wrap { @include custom-icon($mainColor:$warning);}
            }
            &.blue {
                .k-progressbar { 
                    background-color: rgba($series-d,0.1); 
                    .k-progress-status-wrap .k-progress-status {
                        color: $series-d;
                    }
                }
                .k-state-selected { background: $series-d; }
                .icon-wrap { @include custom-icon($mainColor:$series-d);}
            }
        }

        //Pie chart
        .k-arcgauge {
            .k-arcgauge-label h2 { 
                font-size: rem(39); 
                margin-top: rem(6);
            }

            svg path {
                stroke-width: 20 !important;
            }
        }
        
    }
}

//Rating
.k-rating-container .k-rating-item {
    .k-icon {
        font-size: rem(32);
        color: rgba($black, 0.2);
    
        &::before {content: "\e303";}
    }
    &.k-state-selected .k-icon { color: $warning;}
}

//Animal Table Tab 
#animalTableSection {
    .k-tabstrip-items {
        li {
            span {
                font-size: rem(15);
    
                &::before {
                    content: '';
                    font-family: $icon-font;
                    color: $primary;
                    font-size: rem(18);
                    display: inline-block;
                    font-weight: 300;
                    margin-right: $margin-s;
                }
            }
    
            &.k-state-active span::before {color: $white;}
            &.k-first span:before { content: '\f6c8';}
            &.k-last span:before { content: '\f711';}
        }
    }

    .k-window-content .k-columnmenu-actions button{
        flex: 0;
    }
}
div[aria-labelledby='entryAnimalTableDialog'] {
    max-width: rem(1200);
    min-width: rem(600);
    left: 0; right: 0;
    max-height: 80%;

    .k-combobox { width: auto;}
}
.sell-details-form fieldset:not(.k-form-buttons) {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0 -#{$margin-s} !important;

    .k-form-field {
        width: 100%;
        min-width: 12.5rem;
        margin: 0 $margin-s $margin-l;
        max-width: none;
    }
}
//Holding Profile Page
#holdingPlaceholder {
    background: rgba($info,0.04);
    border: 1px solid rgba($info,0.1);
    @include center;
    width: 100%;
    height: 100%;
    min-height: rem(400);
    border-radius: $border-radius;

    #infoBoxMessage {
        border: 1px solid $border-lighter-color;
        background: $white;
        border-radius: $border-radius;
        height: rem(224);
        width: rem(640);
        display: flex;
        align-items: stretch;

        .icon {
            background: rgba($info,0.15);
            width: rem(160);
            height: 100%;
            @include center;
            border-radius: $border-radius 0 0 $border-radius;

            i {
                border-radius: 100%;
                border:3px solid $series-e;
                color: $series-e;
                width: rem(46);
                height: rem(46);
                @include center;
                font-size: rem(20);
            }
        }
        .txt {
            @include center;

            h3 {
                padding: $margin-l;
                text-align: center;
            } 
        }
    }
}
#general-information {
    position: relative;
    border-bottom: 1px solid $border-lighter-color;
    margin-bottom: $margin-l;

    #holding-structure {
        .checkbox-wrapper {
            margin-right: $margin-m;
        }
    }

    .btn-functions {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.holding-form form fieldset:not(.k-form-buttons) {
    display: flex;
    justify-content:space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0 -#{$margin-s} !important;

    .k-form-field {
        width: 100%;
        min-width: rem(200);
        margin: 0 $margin-s $margin-m;
    }
}

//2 Column Grid
.two-column-grid {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;

    .k-form-fieldset {
        flex:1 0 50%;
        margin: 0;
    }
}

// Dial Information Window
div[aria-labelledby='DealInformationWindow'] {
    max-width: 1400px;
    max-height: 90%;

    #dealInformationDialogGrid .k-form-fieldset {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 0 $margin-m;
        justify-content: flex-start;

        .k-form-field {
            max-width: rem(192);
            width: 100%;
            margin: 0 0 $margin-m;
            padding: 0 $margin-xs;
        }
    }
}

//Logs -> Ambulatory Log Form
#AmbulatoryLogForm {
    max-width: rem(700);

    fieldset { 
        padding: 0 $margin-m;

        .k-form-field {
            max-width: rem(320);
        }
    }
}

//Logs-> FooderForm
#fodderForm {
    .k-form-buttons, .k-form-field {
        max-width: rem(315);
    }
}

