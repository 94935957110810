#content {
    .k-drawer-start {
        overflow-y: auto;
        height: calc(100% - (#{$header-height} + #{$margin-xs}));  //header height
        border: none;
        background: $base-bg;
        position: fixed;
        left: 0;
        top: $header-height;
        z-index: 100;
        flex-basis: $logo-section-width !important;
        width: $logo-section-width;

        .k-drawer-wrapper{
            height: 100%;
            width: 100% !important;
            padding: 0 0 $margin-m;

            .k-drawer-separator {
                background-color: rgba($black, 0.08);
                margin: $margin-s $margin-m;
            }

            .k-drawer-items li {
                font-family: $main-font;
                position: relative;
                border-radius: 0;
                @include prefix(transition, color 0.3s ease);

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: $primary;
                    width: 3px;
                    height: 0;
                    border-radius: 0 $border-radius $border-radius 0;
                    @include prefix(transition, all 0.3s ease);
                }

                .k-item-text {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    padding: 0 $margin-s 0 $margin-m;
                }

                .k-icon { 
                    line-height: rem(35); 
                    color: rgba($black, 0.2);
                    font-size: rem(20);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0 0 $margin-s;
                    @include prefix(transition, all 0.3s ease);

                    &.undefined { display: none !important;}
                }

                &.k-drawer-item {
                    color: $base-text;
                    font-size: $base-font-size;
                    line-height: rem(35);

                    &:hover, 
                    &.k-state-hover,
                    &.k-state-selected {
                        color: $primary;
                        background: $white;

                        &:before { height: 100%;}
                        .k-icon { color: $primary; }
                    }

                    &:focus, &.k-state-focused {
                        background-color: $white;
                        box-shadow: none;
                    }
                }
                &.sub-drawer-item {
                    display: flex;
                    align-items: center;
                    font-size: calc(#{$base-font-size} - 2px);
                    line-height: calc(38px);
                    color: $tertiary;
                    padding:0 $margin-m;
                    margin-left: calc(#{$margin-l} + 10px);
                    cursor: pointer;
                    visibility: visible;
                    opacity: 1;
                    display: block;

                    &:before { 
                        height: 100%;
                        background: $border-lighter-color;
                        width: 1px;
                    }

                    &:hover, 
                    &.k-state-hover,
                    &.k-state-selected {
                        color: $base-text;

                        &:before { background: $primary;}
                    }

                    &.last {
                        margin-bottom: $margin-s;
                    }

                    .k-item-text {
                        display: block;
                        padding: 0 !important;
                    }
                }
            }
        }
    }
    .k-drawer-expanded {
        .k-drawer-start {
            flex-basis: $drawer-expanded-width !important;
            width: $drawer-expanded-width;
        }
        .main-content {
            padding: $margin-l $margin-l $margin-l calc(#{$drawer-expanded-width} + #{$margin-l});
        }
        .k-drawer-wrapper .k-drawer-items li {
            &.k-drawer-item {
                span[class*="k-i-arrow-chevron"]{ display: none;}
            }

            &.sub-drawer-item {
                opacity: 0;
                visibility: hidden;
                display: none;
            }
        }
    }
}
  