.k-stepper {
    margin: $margin-xl 0;

    .k-step {
        .k-step-indicator {
            @include element-styling($white, 2px solid $border-color, $base-text);
            width: rem(40);
            height: rem(40);

            &:after {
                box-shadow: inset 0 0 0 2px $white;
                display: block;
            }

            .k-step-indicator-text { font-size: $base-font-size;}

            .k-step-indicator-icon {
                font-size: rem(20);
                &.k-i-warning { color: $error;}
            }
        }

        .k-step-label .k-step-text {
            font-family: $headline-font;
            font-size: rem(17);
            color: $base-text;
        }

        &:hover, &.k-step-hover {
            .k-step-indicator {
                @include element-styling($white, 2px solid $border-color, $base-text);
            }
        }


        &.k-step-current,
        &.k-step-done {
            .k-step-indicator {
                @include element-styling($primary, 1px solid $primary, $white);

                .k-step-indicator-icon {
                    color: $white !important;
                }
            }

            &:hover, &.k-step-hover {
                .k-step-indicator {
                    @include element-styling($primary, 1px solid $primary, $white);
                }
            }

            &.k-step-error {
                .k-step-indicator {
                    @include element-styling($white, 1px solid $error, $error);

                    .k-step-indicator-icon.k-i-warning { color: $error !important;}
                }
            }
            &.k-step-success {
                .k-step-indicator {
                    @include element-styling($success, 1px solid $success, $white);
                }
            }
        }
    }

    .k-step-list-horizontal ~ .k-progressbar {
        top: 25px;
        background-color: $border-lighter-color;
        grid-column: 1 / 7;

        .k-state-selected {
            @include element-styling($success, 1px solid $success, $white);
        }
    }
}