@import './../mixins/breakpoints';
@import './../mixins/grid';
@import './../mixins/forms';
@import './../mixins/spacings';
@import './../mixins/button';
@import './../mixins/utilities';

//Browser Support Prefix
@mixin prefix($property, $parameters...) {
    @each $prefix in -webkit-, -moz-, -ms-, -o-, "" {
      #{$prefix}#{$property}: $parameters;
    }
}

//Center (horizontal/vertical) a content within a container
@mixin center($horizontal: true, $vertical: true) {
    display: flex;
    @if ($horizontal and $vertical) {
      align-items:center;
      justify-content: center;
    } @else if ($horizontal) {
        justify-content: center;
    } @else if ($vertical) {
        align-items:center;
    }
}

//Status badge
@mixin status-badge($status: disabled, $font-size:12px) {
    font-size: $font-size;
    line-height: 1.3em;
    border-radius: $border-radius;
    padding: $margin-s;

    $statusBGColor: map-get((
        disabled : $border-lighter-color,
        active : $series-bg-c,
        inactive : $border-lighter-color,
        canceled: $series-bg-a,
        partially: $series-bg-d,
        pending: $series-bg-b
    ), $status);

    $statusColor: map-get((
        disabled : $disabled-text,
        active : $series-c,
        inactive : $disabled-text,
        canceled: $series-a,
        partially: $series-d,
        pending: $series-b
    ), $status);

    color: $statusColor;
    background: $statusBGColor;
    &.k-text-error, 
    &.k-color-error {
        background:1px solid $series-a;
        color: $series-bg-a !important;
    }
}

//Clearfix
@mixin clearfix {
    &:after {
        content: "";
        clear: both;
        display: table;
    }
}

//Custom icon
@mixin custom-icon($mainColor: $primary, $size: rem(90)) {
    width: $size;
    height: $size;
    background: rgba($mainColor, 0.1);
    border-radius: 50%;
    @include center;

    i {
        border-radius: 100%;
        border:3px solid $mainColor;
        color: $mainColor;
        width: calc(#{list.slash(#{$size}, 2)});
        height: calc(#{list.slash(#{$size}, 2)});
        @include center;
        font-size: rem(18);
    }
}