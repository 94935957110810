.k-column-list-item:hover, 
.k-columnmenu-item:hover {
    color: inherit;
    background: none;
}
.k-checkbox {
    border-color: $border-color;
    @include prefix(transition, border 0.3s ease);

    &:checked, &.k-checked {
        @include element-styling($primary, 1px solid $primary, $white);
    }
    &:indeterminate, &.k-state-indeterminate, &.k-indeterminate {
        @include element-styling($white, 1px solid $border-color, $primary);
    }
    &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;

        + label { cursor: not-allowed; pointer-events: all !important;}
    }
    &:hover, &:focus {
        border-color: $primary;
        box-shadow: none;
    }
    + label {
        color: $base-text;
    }
    &.not-allowed:hover { cursor: not-allowed !important; pointer-events: all !important;}
}