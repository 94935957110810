@import './functions';

:root {
  --color-primary: #ee3641;
  --color-primary-rgb: 238, 54, 65;
  --color-secondary: #dcae97;
  --color-secondary-rgb: 220, 174, 151;
}

//Main kendo theme variables
$black: #000;
$white: #fff;

$border-radius-small: 5px;
$border-radius: 10px;
$border-radius-big: 20px;
$primary: var(--color-primary);
$primary-rgb: var(--color-primary-rgb);
$secondary: var(--color-secondary);
$secondary-rgb: var(--color-secondary-rgb);
$tertiary: #818181;
$contained: #b9b9b9;
$contained-selected: #b3b3b3;
$contained-btn: #ebebeb;

$info: #0058e9;
$success: #37b400;
$warning: #ffc000;
$error: #f31700;

$body-text: #1f1f1f;
$body-bg: #fff;
$headings-text: #1f1f1f;
$subtle-text: #424242;
$disabled-text: #9e9e9e;
$component-text: #000000;
$component-bg: #fff;
$base-text: rgba($black, 0.87);
$secondary-text: rgba($black, 0.54);

$base-bg: #fafafa;
$hovered-text: #1f1f1f;
$hovered-bg: #fafafa;
$selected-text: #1f1f1f;
$selected-bg: #ff6358;
//$link-text: #ee3641;
//$link-hover-text: #d0916f;

$series-a:    #a26c6b;
$series-bg-a: #ffe1e1;
$series-b:    #8b7f50;
$series-bg-b: #fef9e5;
$series-c:    #5d9379;
$series-bg-c: #dbf7e8;
$series-d:    #3468b8;
$series-bg-d: #e6eaf3;
$series-e:    #aa46be;
$series-bg-e: #ebe9f6;

$base-border: rgba($black, 0.42);
$border-color: #cacaca;
$border-lighter-color: #eee;

$shadow-glow-soft: 0px 0px 10px rgba($black, 0.1);
$shadow-glow-medium: 0px 0px 10px rgba($black, 0.25);
$shadow-glow-strong: 0px 0px 10px rgba($black, 0.5);
$shadow-primary-soft: 0px 0px 10px rgba($primary, 0.1);

$component-height: 38;

// Font families
$headline-font:     'PT Sans Narrow', sans-serif;
$main-font:         'Roboto', sans-serif;
$icon-font:         'Font Awesome 5 Pro';
$icon-font-brands:  'Font Awesome 5 Brands';

//Margins
$margin-xs:         4px;
$margin-s:          8px;
$margin-m:          16px;
$margin-l:          24px;
$margin-xl:         40px;

//Typography
$base-font-size:    16px;
$base-line-height:  24px;

$icon-size-s:       14px;
$icon-size-m:       20px;
$icon-size-l:       30px;

// Position
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;

// Options
$enable-grid-classes:         true !default;
$enable-cssgrid:              false !default;
$enable-important-utilities:  true !default;

// Spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// Grid columns
$grid-columns:                12 !default;
$grid-gutter-width:           15px !default;
$grid-row-columns:            6 !default;

$gutters: $spacers !default;

// Container padding
$container-padding-x: $grid-gutter-width * .5 !default;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");