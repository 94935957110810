.k-progressbar {
    border-radius: $border-radius-big;
    overflow: visible;

    .k-progress-status-wrap .k-progress-status {
            font-size: rem(15);
            font-family: $headline-font;
            color: $primary;
            transform: translateX(10px);
            opacity: 0;
            visibility: hidden;
            @include prefix(transition, all 0.3s ease);
    }

    &:hover .k-progress-status-wrap  .k-progress-status{
        opacity: 1;
        visibility: visible;
        transform: none;
    }   
}
.k-state-selected { 
    border-radius: $border-radius-big; 
    .k-progress-status-wrap .k-progress-status {color: $white !important;}
}
