//Label
.k-textbox-container, 
.k-floating-label-container {
    padding-top: $margin-l;

    .k-combobox  {
        min-width: 12.5rem;

        #animalCoolingContainer & { min-width: rem(250); }
    }

    label, .label {
        @include form-label;
        top: $margin-xs !important;
        left: 0 !important;
        display: flex;
        align-items: center;
        max-width: none !important;
    }

    &.k-state-empty.k-state-focused label {
        @extend .label;
    }
}
.k-label {
    @include form-label;
}

//Text Input
input[type='text'],
input.k-textbox {
    @include form-field;
}
.k-text-disabled + .k-form-field-wrap,
.k-text-disabled + .k-numerictextbox { cursor: not-allowed; }

//TextArea
.k-textarea {
    @include form-field;
    height: auto;
    min-height: rem(100);
}

//Numeric Input
.k-numeric-wrap {
    @include form-field;
    border-color: $border-color !important;
    padding: 0 !important;

    &:hover {
        @include element-styling($white, 1px solid $primary, $secondary-text);
        border-color: $primary !important;
        transition: none;
    }

    .k-state-focused & {
        @include element-styling($white, 1px solid $primary, $secondary-text);
        transition: none;
        box-shadow: $shadow-primary-soft;
    }

    &.k-state-invalid {
        @include element-styling($white, 1px solid $error, $error);
    }

    input {
        @include special-input-field();
    }

    //Arrows area
    .k-select {
        @include element-styling(none, none, $secondary-text);
        background-image: none;

        .k-link {
            @include prefix(transition, border 0.3s ease);
            
            &:hover, &.k-state-hover {
                @include element-styling(none, none, $body-text);
            }
        }
    }
}

//DropDown
.k-combobox .k-dropdown-wrap,
.k-dropdown .k-dropdown-wrap {
    @extend .k-numeric-wrap;

    .k-input {
        height: 100%;
    }

    &.k-state-focused {
        @include element-styling($white, 1px solid $primary, $secondary-text);
        transition: none;
        box-shadow: $shadow-primary-soft;
        border-color: $primary !important;
    }

    &:hover, &.k-state-hover {
        .k-select {
            @include element-styling(none, none, $body-text);
        }
    }

    input {
        padding: $margin-s $margin-s $margin-s $margin-xl !important;
    }
    
    .k-clear-value { display: none;}
    .k-searchbar::before {left: $margin-m;}
}

//Datepicker & Datetime Picker
.k-datepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap {
    @extend .k-numeric-wrap;

    .k-dateinput, .k-dateinput-wrap, .k-input {
        @include element-styling(none, none, $body-text);
    }

    .k-state-focused & {
        @include element-styling($white, 1px solid $primary, $secondary-text);
        transition: none;
        box-shadow: $shadow-primary-soft;
    }
}

//Autocomplete input field
.k-autocomplete {
    position: relative;
    width: 200px;
    height: $component-height;

    .k-searchbar input {
        width:100%;
    }
}
.k-searchbar {
    position: relative;
    &::before {
        content: '\f002';
        color: $primary;
        font-size: $base-font-size;
        font-family: $icon-font;
        position: absolute;
        left: $margin-m;
        top: $margin-s;
        font-weight: 700;
    }

    input {
        padding-left: $margin-xl !important;
    }
}
.k-i-close {
    position: absolute;
    top: $margin-xs;
    right: $margin-xs;
}

//Multiselect input
.k-multiselect {
    @include form-field;
    padding: 1px !important;
    height: auto;

    .k-multiselect-wrap {
        border: none;
        padding: $margin-s $margin-l 0 $margin-xs;
        position: relative;

        .k-searchbar {
            float: none;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 96%;

            .k-input {
                border: none;
                background: none;
            }
        }

        .k-reset {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: -#{$margin-xs};

            + .k-searchbar { display: none;}

            li.k-button {
                margin-bottom: 2px;
                top: -7px;
                display: flex;
                align-items: center;
                padding: $margin-xs $margin-xs $margin-xs $margin-s;
                height: 28px;
                background: rgba($primary,0.1);
                color: $base-text;
                cursor: pointer;
                @include prefix(transition, all 0.3s ease);

                .k-select .k-icon {
                    position: relative;
                    top: auto;
                    margin-left: $margin-s;
                    opacity: 1;
                }

                &:hover {
                    background: rgba($primary,1);
                    color: $white;

                    .k-select .k-icon { color: $white;}
                }
            }
        }

        + .k-i-close {top: $margin-xs;}

        ul li {
            @include prefix(background, all .3s ease-in-out);
            @include element-styling(rgba($primary, 0.08), rgba($primary, 0.08), $base-text);
            font-size: calc(#{$base-font-size} - 4px);
            line-height: $base-line-height;

            &:hover, &:focus {
                background: rgba($primary, 0.15);
            }
        }
    }
}

//Upload field
.k-upload {
    @include element-styling(none, none, $base-text);

    .k-dropzone {
        @include form-field;

        &:hover, &:focus{
            .k-dropzone-hint { color: $primary }
            .k-upload-button { background: rgba($primary, 0.08);}
        }

        .k-upload-button {
            background: $contained-btn;
            color: $base-text;
            font-size: calc(#{$base-font-size} - 2px);
            line-height: calc(#{$base-line-height} - 2px);
            padding: 0px $margin-s;
            border: none;
            border-radius: 0;
            margin-right: $margin-m;
            @include prefix(background, all .3s ease-in-out);
            box-shadow: none !important;

            &:hover, &:focus {
                background: rgba($primary, 0.15);
            }
        }

        .k-dropzone-hint {
            color: $base-text;
            font-size: calc(#{$base-font-size} - 5px);
            line-height: calc(#{$base-line-height} / 2);  
            color: $base-border;
            font-weight: 500;
            @include prefix(color, all .3s ease-in-out);
        }

        .k-upload-status-total {
            @extend .k-dropzone-hint;
            color: $success;
        }
    }

    .k-upload-files {
        background: $white;
        border: 1px solid $border-color;
        border-top: 0;
        border-radius: 0  0 $border-radius $border-radius;
        padding: $margin-xs;

        .k-file-name-size-wrapper { margin-right: $margin-m;}

        .k-file.k-state-focused {
            box-shadow: none;
            border-color: none;
        }

        .k-file-validation-message {
            &.k-text-error {
                color: $error;
            }
            &.k-text-success {
                color: $success;
            }
        }

        .k-file-name {
            font-size: calc(#{$base-font-size} - 2px);
            line-height: $base-line-height;
            color: $base-text;
            margin-bottom: $margin-xs;
        }

        .k-upload-status button {
            background: none;

            &:before {content: none;}

            &:hover, &:focus, &:active {
                color: $primary;
                background: none;
                border: none;
                box-shadow: none;
            }
        }
    }
}

//Animation popup
.k-animation-container {
    z-index: 10005;
    
    > .k-popup {
        box-shadow: none;
        color: $body-text;
        background: $white;
        border-color: $border-lighter-color;
        border-top: 0;
        border-radius: 0  0 $border-radius $border-radius;
        padding: $margin-xs 0 0;

        .k-list .k-item {
            font-family: $main-font;
            font-weight: 400;
            color: $base-text;
            font-size: calc(#{$base-font-size} - 2px) !important;
            line-height: calc(#{$base-line-height} - 6px) !important;
            padding: $margin-xs $margin-m;
            border:1px solid transparent;

            &:focus, &.k-state-focused, &:focus {
                box-shadow: none;
            }
            &.k-state-selected {
                background: $white;
                color: $primary;
            }
            .k-icon {@include prefix(color, all .3s ease);}

            &:hover {
                background: rgba($primary, 0.05);
                border:1px solid rgba($primary, 0.1);

                .k-icon {color: $primary;}
            }
        }
    }
}

.k-form-hint, .k-form-error { font-size: 11px;}