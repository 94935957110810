$spaces: ("xs": "4", "s": "8", "m": "16", "l": "24", "xl": "40");
$sides: (top, bottom, left, right);

@each $side in $sides {
    @each $name, $value in $spaces {
        .m#{str-slice($side, 0, 1)}-#{$name} {
            margin-#{$side}: #{$value}px !important;
        }
    
        .p#{str-slice($side, 0, 1)}-#{$name} {
            padding-#{$side}: #{$value}px !important;
        }
    }
}

$spaces: ("auto": "auto");
$sides: (top, bottom, left, right);

@each $side in $sides {
    @each $name, $value in $spaces {
        .m#{str-slice($side, 0, 1)}-#{$name} {
            margin-#{$side}: #{$value} !important;
        }
    
        .p#{str-slice($side, 0, 1)}-#{$name} {
            padding-#{$side}: #{$value} !important;
        }
    }
}
