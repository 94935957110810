.k-tabstrip {
   .k-tabstrip-items {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .k-item {
            @include element-styling($white, 1px solid $border-lighter-color, $body-text);
            height: rem($component-height);
            @include center(true, true);
            flex-grow: 1;
            text-align: center;
            border-radius: 0;
            @include prefix(transition, all 0.3s ease);

            &:first-child {
                border-radius:$border-radius 0 0 $border-radius;
            }
            &:last-child {
                border-radius:0 $border-radius $border-radius 0;
            }

            span {
                font-size:calc(#{$base-font-size} - 4px);
                line-height: calc(#{$base-line-height} - 8px);
                text-transform: uppercase;
                display: inline-block;
            }

            &:hover {
                @include element-styling($white, 1px solid $primary, $body-text);
            }

            &:focus,
            &:active {
                @include element-styling(rgba($primary,0.08), 1px solid $primary, $body-text);
            }

            &.k-state-active {
                @include element-styling($primary, 1px solid $primary, $white);
            }

            &.k-state-disabled {
                @extend %disabled-state;
            }
        }
    }
    > .k-content {
        border: none;
        color: $body-text;
        padding: $margin-m 0;
    }

    .k-animation-container-relative {
      display: block !important;
    }
}