.k-grid,
.k-grid-table {
    border-color: transparent !important;
    color: inherit;
    background-color: inherit;

    .k-toolbar {
        @extend .k-grid;
        padding: $margin-m 0;
    }
    //Table Header
    .k-grid-header-wrap { border: none;}

    .k-grid-header {
        border: 1px solid $border-lighter-color;
        background: $white;
        border-bottom: none;
        border-radius:$border-radius $border-radius 0 0;
        padding: 0;

        th {
            position: relative;
            vertical-align: middle;
            font-family: $main-font;

            &:after {
               content: '';
               width: 100%;
               height: 1px;
               background: $primary;
               position: absolute;
               left: 0; bottom: 0;
               visibility: hidden;
               opacity: 0;
               @include prefix(transition, all 0.3s ease);
            }

            .k-icon.k-i-more-vertical {
                margin-top: 12px;
            }
            .k-link {
                font-size: calc(#{$base-font-size} - 4px);
                line-height: #{$base-line-height};
                color: $secondary-text;
                font-weight: 700;
                padding: $margin-m;
                @include prefix(transition, color 0.3s ease);

                .k-icon {
                    color: $primary;
                }
                white-space: break-spaces;
            }
            &:hover,
            &.k-sorted {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .k-link,
                .k-icon.k-i-more-vertical {
                    color: $base-text;
                }
            }
        }
    }
    //Table Content
    .k-grid-content { overflow-y:auto; }

    .k-grid-container,
    tbody {
        border: 1px solid $border-lighter-color;
        border-bottom: 0;

        tr {
            border: none;
            background:  inherit !important;

            &:nth-child(2n) > td,
            &:nth-child(2n):hover > td {
                background: rgba($black, 0.01);
            }

            > td {
                border-bottom: 1px solid $border-lighter-color;
                background: inherit;
                @include prefix(transition, border 0.3s ease);
                font-size: calc(#{$base-font-size} - 2px);
                line-height: calc(#{$base-line-height} - 6px);
                color: $base-text;
                font-weight: 400;
                padding: $margin-s $margin-m;
                font-family: $main-font;

                .cell-status {
                    min-width: rem(120);
                    display: inline-block;

                    &#active, &#accepted {
                        @include status-badge(active)
                    }
                    &#inactive {
                        @include status-badge(inactive)
                    }
                    &#partially {
                        @include status-badge(partially)
                    }
                    &#disabled {
                        @include status-badge(disabled)
                    }
                    &#canceled {
                        @include status-badge(canceled)
                    }
                    &#pending {
                        @include status-badge(pending)
                    }
                }

                > a {
                    text-decoration: underline;

                    &:hover { text-decoration: none; }
                }
            }
            &:hover td {
                background: inherit;
                border-bottom: 1px solid $primary;
            }
        }
    }

    col.k-sorted, th.k-sorted {
        background: rgba($secondary, 0.05);
    }

    .k-grid-pager {
        border: 1px solid $border-lighter-color;
        border-top: none;
        border-radius: 0 0 $border-radius $border-radius;
        background: $white;

        .k-link {
            color: $secondary-text;
            opacity: 1;
            cursor: pointer;

            &:hover {
                color: $body-text;
                background: none;
                outline: none;
            }
        }

        .k-pager-numbers {
            li {margin-right: $margin-s;}
            .k-link {
                border-radius: 50%;
                width: $component-height;
                height: $component-height;
                display: flex;
                align-items: center;
                font-size: $base-font-size;

                &:hover,
                &.k-state-hovered {
                    color: $primary;
                    background-color: rgba($primary, 0.04);
                    outline: none;
                }
                &.k-state-selected {
                    color: $body-text;
                    background-color: rgba($primary, 0.04);
                    border:2px solid $primary;
                }
            }
        }

        .k-pager-info {
            font-size: calc(#{$base-font-size} - 4px);
            line-height: calc(#{$base-line-height} - 8px);
            color: rgba($black, 0.38);
        }
    }
}

//Sorting popup styles
.k-grid-columnmenu-popup .k-columnmenu-item-wrapper{
    padding: $margin-s;

    .k-columnmenu-item {
        padding: $margin-s 0 $margin-m;
        color: $base-text;

        &:hover {
            color: $base-text;
            background: none;
        }
    }

    .k-filter-menu .k-widget,
    .k-filter-menu .k-radio-list,
    .k-filter-menu .k-textbox,
    .k-filter-menu .k-searchbox {
        margin: 0 0 $margin-s;
        width: 100%;
    }

    .k-columnmenu-actions {
        padding: $margin-s 0;
        @include prefix(display, flex);
        @include prefix(justify-content, space-between);

        button { width: 99%;}
    }
}