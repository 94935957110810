.k-notification-group {
    .k-child-animation-container {
        display: flex;
        justify-content: flex-end;
    }
    .k-notification {
        box-shadow: $shadow-glow-soft;
        padding: calc(#{$margin-m} - 4px) $margin-m;
        border-radius: $border-radius;
        font-size: calc(#{$base-font-size} - 2px);
        margin-right: rem(2);
        min-width: rem(350);

        .k-notification-wrap {
            align-items: center;

            > .k-icon { margin-right: $margin-s; }
            > .k-i-close {right: 0; top: auto;}
            .k-notification-content {margin-right: $margin-m; }
        }

        &.k-notification-error {
            border-color: $series-bg-a;
            color: $error;
            background-color: $series-bg-a;
        }
        &.k-notification-success {
            border-color: $series-bg-c;
            color: $series-c;
            background-color: $series-bg-c;
        }
        &.k-notification-warning {
            border-color: $series-bg-b;
            color: $series-b;
            background-color: $series-bg-b;
        }
    }
}