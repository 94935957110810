.k-overlay {
    background-color: $base-text;
}
.k-window {
    border-color: $border-lighter-color;
    color: $base-text;
    background-color: $white;
    box-shadow: 0 3px 3px 0 rgba($black, 0.1);
    min-width: 440px;
    border-radius: $border-radius;

    .k-dialog-titlebar {
        border: none;
        border-bottom: 1px solid $border-lighter-color;
        color: $base-text;
        background-color: $white;
        border-radius: $border-radius $border-radius 0 0;

        .k-window-title {
            color: $base-text;
            font-size: calc(#{$base-font-size + 2px});
        }
    }
    .k-window-actions, 
    .k-dialog-actions {
        .k-button {
            &:before, &:after { content: none;}

            .k-icon { @include prefix(transition, color 0.3s ease);}

            &:hover, &:focus {
                .k-icon { color: $primary;}
            }
        }
    }
    .k-window-content {
        padding: $margin-l;

        .k-form-field {
            max-width: rem(250);
            width: 100%;
            margin: 0 auto calc(#{$margin-m} - 6px);

            //Switch
            &.switch-form-field {
                display: flex;
                align-items: center;

                label {margin-right: $margin-s;}
            }
        }
        .k-form-buttons {
            max-width: rem(250);
            width: 100%;
            margin: $margin-m auto 0;
            display: flex;
            justify-content: space-between;
            
            button {
                width: 49.5%;
            }
        }
    }
    .column-content {
        display: flex;
        flex-wrap: wrap;
        max-height: none;
        max-width: 900px;
        width: 100%;

        .k-column-list-item {
            flex-basis: 50%;
            flex-grow: 1;
            padding: $margin-s;
            color: $base-text;
            font-size: $base-font-size;
            line-height: $base-line-height;

            &:hover {
                color: $base-text;
                background-color: rgba($primary, 0.04);
            }
        }
    }
    .k-columnmenu-actions {
        border-top: 1px solid $border-lighter-color;
        padding-top: $margin-l;
        margin-top: $margin-l;
        display: flex;
        justify-content: flex-end;
    }
}