//Top bar
$logo-section-width: rem(300);
$drawer-expanded-width: rem(50);
$header-height: rem(95);

.header {
  background-color: $component-bg !important;
  border-bottom: 3px solid $base-bg !important;
  padding: 0;
  border: none;
  box-sizing: border-box;
  outline: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;

  .k-appbar-section {
    padding: $margin-s $margin-m;
    display: flex;
    align-items: center;

    &.logo-section {
      @include center(true, false);
      background: $base-bg;
      width: $logo-section-width;

      .main-logo {
        display: inline-block;

        svg {
          width: 60px;
          height: auto;

          .st0 {
            fill: $secondary;
          }
          .st1 {
            fill: $primary;
          }
        }
      }
    }

    &.actions,
    &.holdingNameContainer {
      padding: $margin-s;
    }
  }

  .section {
    display: flex;
    justify-content: space-between;
    width: calc(99% - #{$logo-section-width});
  }

  button {
    &#font-resizing {
      padding: $margin-s;
    }
  }
  //Avatar section
  .avatar {
    img {
      border-radius: $border-radius;
      max-width: rem(36);
    }
    .k-button:after {
      content: none !important;
    }
  }
}
//Holding Name
.holdingNameContainer {
  font-size: $base-font-size - 2px;

  .name {
    font-weight: 300;
    padding: 0 $margin-xs;
  }
}

//Avatar menu section
#avatar-menu {
  @include prefix(transform, translate3d(-#{$margin-s}, $margin-s, 0));

  > .k-popup {
    border-color: $border-lighter-color;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba($black, 0.03), 0 2px 3px 0 rgba($black, 0.02);
  }
}
.avatar-menu-item {
  color: $base-text;
  font-size: $base-font-size;
  line-height: calc(#{$base-line-height} + 2px);
  white-space: nowrap;

  .k-icon {
    color: rgba($black, 0.12);
    margin-right: $margin-s;
    display: inline-block;
    font-size: calc(#{$base-font-size} + 3px);
  }
}

#hamburger-menu-btn {
  @include center(true, true);
  width: rem(48) !important;
  height: rem(48) !important;

  .k-i-menu {
    color: $white;
  }
}

//Main menu
.k-menu {
  .k-menu-item {
    padding: 0 $margin-m;

    &:focus,
    &.k-state-focused {
      box-shadow: none !important;
    }

    span,
    > a {
      font-size: rem(15);
      line-height: rem(26);
      color: $base-text;
      text-transform: uppercase;
      display: block;
      padding: $margin-s;
      font-weight: 500;
      position: relative;
      @include prefix(transition, color 0.3s ease);

      &::after {
        content: '';
        background: $primary;
        width: 0;
        height: rem(3);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        @include prefix(transition, all 0.3s ease);
        opacity: 0;
        visibility: hidden;
        border-radius: 0 0 $border-radius $border-radius;
      }

      &:hover,
      .main-menu-active & {
        color: $black;

        &:after {
          opacity: 1;
          visibility: visible;
          width: 100%;
        }
      }
    }

    &.main-menu-active span,
    &.main-menu-active > a {
      color: $black;

      &:after {
        opacity: 1;
        visibility: visible;
        width: 100%;
      }
    }
  }
}

.public-header {
  background-color: $component-bg !important;
  border-bottom: 3px solid $base-bg !important;
  padding: 0;
  border: none;
  box-sizing: border-box;
  outline: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;

  .k-appbar-section {
    padding: $margin-s $margin-m;
    display: flex;
    align-items: center;

    &.logo-section {
      @include center(true, false);
      background: $base-bg;
      width: $logo-section-width;

      .main-logo {
        display: inline-block;

        svg {
          width: 60px;
          height: auto;

          .st0 {
            fill: $secondary;
          }
          .st1 {
            fill: $primary;
          }
        }
      }
    }
  }
}
