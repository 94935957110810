.switch-form-field {
    &.inline-label > div {
        display: flex;
        align-items: center;

        label { margin-right: $margin-s;}
    }
}
.k-switch {
    &.k-switch-off {
        .k-switch-container {
            color: $base-text;
            background-color: $white;
            box-shadow: inset 0 0 0 1px $border-color;
            @include prefix(transition, background-color 0.3s ease);
        }
        .k-switch-handle {
            border-color: $border-color;
            color: $base-text;
            background-color: $contained-btn;
        }

        &:hover .k-switch-container, 
        &.k-state-hover .k-switch-container {
            background-color: rgba($border-color, 0.2);
        }
    }
    &.k-switch-on {
        .k-switch-container {
            color: $base-text;
            background-color: rgba($primary, 0.1);
            box-shadow: inset 0 0 0 1px rgba($primary, 0.3);
            @include prefix(transition, background-color 0.3s ease);
        }
        .k-switch-handle {
            border-color: $primary;
            color: $white;
            background-color: $primary;
        }

        &:hover .k-switch-container, 
        &.k-state-hover .k-switch-container {
            background-color: rgba($primary, 0.2);
        }
    }
}