h1, h2, h3,
h4, h5, h6 {
  font-family: $headline-font;
  color: $base-text;
  line-height: 1.6rem;
  font-weight: 400;
  margin-bottom: $margin-m;
}
h1, .h1 {
  font-size: rem(36);

  &.page-title {
    font-size: rem(40);
    line-height: 116.7%;
    margin-bottom: $margin-m;
  }
}
h2, .h2 {
  font-size: rem(26);
}
h3, .h3 {
  font-size: rem(24);
}
h4, .h4 {
  font-size: rem(22);
}
h5, .h5 {
  font-size: rem(20);
}
h6, .h6 {
  font-size: rem(20);
}
.subtitle-1 {
  font-size: $base-font-size + 2px !important;
  line-height: $base-line-height;
  font-family: $headline-font;
}