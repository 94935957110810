.k-button {
    border-radius: $border-radius;
    padding: $margin-s $margin-m;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    font-size: calc(#{$base-font-size} - 2px);
    line-height: calc(#{$base-line-height} - 8px);;
    background-image: none !important;
    text-transform: uppercase;
    @include prefix(transition, all 0.3s ease);
    height: rem($component-height);
    display: inline-flex;
    align-items: center;
    font-family: $main-font;

    &:active,
    &:focus {
        box-shadow: $shadow-glow-soft !important;
    }
    &:disabled {
        @extend %disabled-state;
    }

    &.k-outline  {
        @include element-styling($white, 1px solid $primary, $primary);

        &.k-button-icontext .k-icon {
            color: $primary;
        }
        &:hover {
            @include element-styling($white, 1px solid $primary, $base-text);
            //box-shadow: $shadow-glow-soft;
        }
        &:focus,
        &:active {
            @include element-styling(rgba($primary, 0.02), 1px solid $primary, $base-text);
            box-shadow: $shadow-glow-soft;
        }
    }

    &.k-button-icontext .k-icon {
        color: $white;
    }

    &.k-button-icon {
        padding: $margin-m;
    }

    &.k-primary,
    &.k-button-primary  {
        @include element-styling($primary, 1px solid transparent, $white);

        &:hover {
            @include element-styling(rgba($primary-rgb,0.9), 1px solid transparent, $white);
        }
        &:focus,
        &:active {
            @include element-styling(rgba($primary-rgb,0.9), 1px solid transparent, $white);
        }

        &.k-button-flat,
        &.k-flat {
            @include element-styling($white, 1px solid transparent, $primary);

            &.k-button-icontext .k-icon {
                color: $primary;
            }
        }
        &.k-button-outline,
        &.k-outline  {
            @include element-styling($white, 1px solid $primary, $base-text);

            &.k-button-icontext .k-icon {
                color: $primary;
                @include prefix(transition, color 0.3s ease);
            }
            &:hover {
                @include element-styling($white, 1px solid $secondary, $base-text);

                &.k-button-icontext .k-icon {
                    color: $secondary;
                }
            }
        }
    }
    &.k-secondary,
    &.k-button-secondary {
        @include element-styling($secondary, 1px solid transparent, $white);

        &:hover {
            @include element-styling(rgba($secondary-rgb,0.9), 1px solid transparent, $white);
        }
        &:focus,
        &:active {
            @include element-styling(rgba($secondary-rgb,0.9), 1px solid transparent, $white);
        }

        &.k-button-flat,
        &.k-flat {
            @include element-styling($white, 1px solid $white, $secondary);

            &.k-button-icontext .k-icon {
                color: $secondary;
            }
        }
        &.k-button-outline,
        &.k-outline  {
            @include element-styling($white, 1px solid $secondary, $secondary);

            &:hover {
                @include element-styling($white, 1px solid $primary, $primary);
            }

            &.k-button-icontext .k-icon {
                color: $primary;
            }
        }
    }

    &.k-error,
    &.k-button-error {
        @include element-styling($error, 1px solid transparent, $white);

        &:hover {
            @include element-styling($error, 1px solid transparent, $white);
        }
        &:focus,
        &:active {
            @include element-styling($error, 1px solid transparent, $white);
        }

        &.k-button-flat,
        &.k-flat {
            @include element-styling($white, 1px solid $white, $error);

            &.k-button-icontext .k-icon {
                color: $error;
            }
        }
        &.k-button-outline,
        &.k-outline  {
            @include element-styling($white, 1px solid $error, $error);

            &:hover {
                @include element-styling($white, 1px solid $error, $error);
            }

            &.k-button-icontext .k-icon {
                color: $error;
            }
        }
    }
}