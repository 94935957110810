//Disabled state
%disabled-state {
    opacity: 0.6;
    box-shadow: none !important;
    cursor: not-allowed;
}

//Form field
@mixin form-field {
    border-radius: $border-radius !important;
    border-width: 1px;
    border-style: solid;
    @include element-styling($white, 1px solid $border-color, $body-text);
    padding: $margin-s !important;
    font-family: $main-font;
    font-size: calc(#{$base-font-size} - 2px);
    line-height: calc(#{$base-line-height} - 4px);
    font-weight: 400;
    box-shadow: none;
    box-sizing: border-box;
    text-indent: 0 !important;
    outline:none;
    @include prefix(transition, all .3s ease-in-out);
    height: rem($component-height);

    &:hover {
        border-color: $primary;
        transition: none;
        outline:none;
        box-shadow: none;
    }
    
    &:-internal-autofill-selected,
    &:focus,
    &:active {
        border-color: $primary;
        transition: none;
        box-shadow: $shadow-glow-soft;
    }

    &:disabled {
        @extend %disabled-state;
        cursor: not-allowed;
    }

    &::-webkit-input-placeholder {
        color: rgba($black, 0.54);
        font-weight: 400;
    }
    &::-moz-placeholder {
        color: rgba($black, 0.54);
        font-weight: 400;
    }
    &:-ms-input-placeholder {
        color: rgba($black, 0.54);
        font-weight: 400;
    }
    &::placeholder {
        color: rgba($black, 0.54);
        font-weight: 400;
    }
}

@mixin special-input-field {
    border:none;
    height:  100%;
    padding: $margin-s !important;
    font-family: $main-font;
    font-size: calc(#{$base-font-size} - 2px);
    line-height: calc(#{$base-line-height} - 4px);
    font-weight: 400;
    box-shadow: none !important;
    color: $base-text;
}

//Form Label
@mixin form-label {
    font-family: $main-font;
    font-weight: 400;
    color: $base-text;
    font-size: calc(#{$base-font-size} - 4px) !important;
    line-height: calc(#{$base-line-height} - 8px) !important;

    &.k-text-error, 
    &.k-color-error {
        border-color: $error;
        color: $base-text !important;
    }
}