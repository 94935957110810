@import './variables';

//Text align positions
$positions: left, center, right;

@each $position in $positions {
  .text-#{$position} {
    text-align: $position !important;
  }
}

// Loop over each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {

  // Generate media query if needed
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Loop over each utility property
    @each $key, $utility in $utilities {
      // The utility can be disabled with `false`, thus check if the utility is a map first
      // Only proceed if responsive media queries are enabled or if it's the base media query
      @if type-of($utility) == "map" and (map-get($utility, responsive) or $infix == "") {
        @include generate-utility($utility, $infix);
      }
    }
  }
}

//Grid
// Row
@if $enable-grid-classes {
  .row {
      @include make-row();

      > * {
      @include make-col-ready();
      }
  }
}

@if $enable-cssgrid {
  .grid {
      display: grid;
      grid-template-rows: repeat(var(--#{$variable-prefix}rows, 1), 1fr);
      grid-template-columns: repeat(var(--#{$variable-prefix}columns, #{$grid-columns}), 1fr);
      gap: var(--#{$variable-prefix}gap, #{$grid-gutter-width});

      @include make-cssgrid();
  }
}

// Columns
@if $enable-grid-classes {
  @include make-grid-columns();
}
