.k-radio {
    border-color: $border-color;
    @include prefix(transition, border 0.3s ease);

    &:checked, &.k-checked {
        @include element-styling($white, 1px solid $primary, $primary);
    }
    &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;

        + label { cursor: not-allowed; pointer-events: all !important;}
    }
    &:hover, &:focus, &.k-state-hover {
        border-color: $primary;
        box-shadow: none;
    }
    + label {
        color: $base-text;
    }
}